import tw from "twin.macro"

export const Wrapper = tw.div`
  max-w-xl mx-auto px-2-4 md:px-3 pt-6-4 md:pt-12-4 pb-6 md:pb-12
`

export const Divider = tw.hr`
  mt-5 mb-3 md:mb-8
`

export const Title = tw.h1`
  text-35 leading-1.28 tracking-3 md:text-50 md:leading-1.2 md:tracking-2 font-bold uppercase text-center mb-3 md:mb-1-2
`
